import request from '@/request'
export const queryStatisticsWorkingday = (data) => {
    return request({
        url:`/api-gateway/service/query-statistics-workingday/${data.pageSize}/${data.pageNum}`,
        method:'post',
        data,
    });
}
export const setStatusWorkingday = (data) => {
    return request({
        url:'/api-gateway/service/setStatusWorkingday',
        method:'post',
        data,
    });
}
